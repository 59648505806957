import { Container, Button,Form, Alert,Table} from 'react-bootstrap';
import {Sida,Rubrik,ContentWrapper,StyckeCompact} from "./ContentStyles";
import React, { useState ,useEffect} from 'react';
import useBackend, {CallAPI, Loading, SpinnerX} from '../services/useBackend';
import {useParams, useNavigate} from 'react-router-dom';
import { FaFilePdf, FaReceipt, FaEnvelope, FaUser } from 'react-icons/fa';
import ReactPlayer from 'react-player';
import {DateTimeToLocal, MultiselectGetOptions, MultiselectGetDefaultValues, escapedNewLineToLineBreakTag, ConcatLocalDateAndTime2ISO, SplitoutLocalDateFromISO, SplitoutLocalTimeFromISO, getOptions} from '../services/misc';

var typingTimeout = null;
var orderGUID = null;

function handleChange(event){
	clearTimeout(typingTimeout);
	typingTimeout = setTimeout(handleSave, 475);
}

function handleSave(){
	const ProductForm = document.getElementById('ProductForm');
	const formData = new FormData(ProductForm);

	const formDataObj = Object.fromEntries(formData.entries());

	formDataObj.Swish       = formData.has('Swish') ? true : false;
    formDataObj.Card        = formData.has('Card') ? true : false;
	formDataObj.TestPayment = formData.has('TestPayment') ? true : false;

	// PatchBackend('/api/admin/shop/'+shopGUID,JSON.stringify(formDataObj));
}

function openPriceList(event){
	// window.open('https://www.nookshop.se/api/info/'+shopGUID+'/Pricelist', '_blank', 'noreferrer');
}

export default function OrderDetails() {
    const {OrderGUID} = useParams();
    orderGUID = OrderGUID;    
	const {resultVariant, resultVisible, resultText, loading, data, Refresh} =  useBackend('/api/admin/orders/'+OrderGUID);

	const navigate = useNavigate();

	const customerClick = (event)  =>  {
        const customerguid = event.currentTarget.getAttribute("customerguid");
        navigate("/customer/"+customerguid);
    }  

	const setOrderStateClick = (event)  =>  {
        const NewState = event.currentTarget.getAttribute("NewState");
        console.log(NewState)
		CallAPI('/api/admin/Orders/'+orderGUID,"PATCH","{\"NewOrderState\":\"10\"}");
    }

	const handleCheckPaymentState = (event) => {
		console.log(orderGUID);
		CallAPI('/api/admin/Orders/'+orderGUID+"/CheckPaymentState","PATCH","");
		Refresh();
	}

    if (!loading) {
        return (
            <ContentWrapper>
                <Container>
                    <Sida>
						<Rubrik>Försäljning (Order: {data.SerialNumber})</Rubrik>		
						<StyckeCompact>
							<>Created:        {DateTimeToLocal(data.Created)}</><br />
							<>SerialNumber:   {data.SerialNumber}</><br /> 
							<>OrderState:       {data.OrderStateText}</><br /><br />
							{data.OrderState==8 &&
								<>
									<Button className="QmeButton" variant="primary" type="button" onClick={handleCheckPaymentState}>Uppdatera Swishstatus</Button>    
								</>
							}
							
						</StyckeCompact>						

						<Rubrik>Kund <span style={{padding:5}} className='sub_' customerguid={data.Buyer.Guid} onClick={customerClick} title="Info "><FaUser /></span></Rubrik>
						<StyckeCompact>
							<>Name:    {data.Buyer.Name}</><br />
							<>SSN:     {data.Buyer.SSN}</><br />
							<>eMail:   {data.Buyer.eMail}</><br />
						</StyckeCompact>

						<Rubrik>Butik</Rubrik>
						<StyckeCompact>
							<>Name:        {data.Shop.Name}</><br />
							<>Description: {data.Shop.Description}</>

						</StyckeCompact>						

						<Rubrik>Varor/Köp</Rubrik>

							<Table  responsive="sm">
								<thead>
									<tr><th>Namn</th><th>Antal</th><th>Pris</th><th>Moms</th></tr>
								</thead>
								<tbody>
									{data.BasketItemList.map((item) => {
										return <tr key={item.Name}><td>{item.Name}</td><td>{item.Qty}</td><td>{item.Price}</td><td>{item.Vat}%</td></tr>
									})}

									<tr><td></td><th>Totalt</th><td>{data.BasketTotalAmount}</td><td>{data.BasketTotalVat}</td></tr>
								</tbody>
							</Table>
                    </Sida>
                </Container>
            </ContentWrapper>
        );
    } else {
        return (<Loading />);        
    }
}